<template>
  <div id="V3Main">
    <!-- Main 캔버스 영역 -->
    <section class="main_artwork" @touchstart="lockScroll" @touchend="unlockScroll">
      <IntroArtwork :headerHeight="headerHeight" :weight="1" :speed="1" :brushSize="3" :sceneScale="sceneScale" />
    </section>

    <!-- Main Video 영역 ================================= -->
    <!-- <div class="main-top">
      <slick ref="slick" :options="_slickOptions" :autoplaySpeed="initialAutoplaySpeed" @afterChange="handleAfterChange">
        <div class="video">
          <div class="video-mobile-text">
            <div class="right">
              <div class="title">
                {{ mainTitle[5] }}
              </div>
              <div class="subtitle">
                <div>Discover new art everyday</div>
                <div>Visualize and share your aesthetics</div>
              </div>
            </div>
          </div>
          <div class="background">
            <video
              class="background-video"
              src="/img/threespace/main/main-banner.mp4"
              type="video/webm"
              loop
              autoplay
              muted
              @contextmenu.prevent.stop
              playsinline
            ></video>
            <div>
              <img v-show="isVideoLoading" class="main-video" :src="placeholderImage" alt="Loading video...">
              <video
                @loadeddata="onVideoLoaded"
                class="main-video"
                src="/img/threespace/main/main-banner.mp4"
                type="video/mp4"
                loop
                autoplay
                :muted="videoOptions.muted"
                @contextmenu.prevent.stop
                playsinline
              ></video>
            </div>
          </div>
        </div>
        <template v-for="(item, i) in 5">
          <div :key="'main'+i">
            <div class="main-slick-container">
              <div class="left">
                <img :class="`main-image-mobile${i+1} main-image-mobile`" :src="`/img/threespace/main/mobile-main${i+1}.png`"/>
                <img class="main-image-pc" :src="`/img/threespace/main/main${i+1}.png`"/>
              </div>
              <div class="right">
                <div class="title">
                  {{ mainTitle[i] }}
                </div>
                <div class="subtitle" v-html="mainDescription[i]"></div>
              </div>
            </div>
          </div>
        </template>
      </slick>
    </div> -->

    <!-- <div class="main-gallery-wrap">
      <div class="top">
        <div class="title">FEATURED DROPS</div> -->
<!--        <div class="subtitle">{{ $t("home.main.title1") }}</div>-->
<!--        <div class="subtitle">{{ $t("home.main.title2") }}</div>-->
      <!-- </div>
      <div class="gallery-section"
           v-if="!(galleryProductHomeItemsRandom === undefined ||
           galleryProductHomeItemsRandom === null ||
           galleryProductHomeItemsRandom.length === 0)"
      >
        <slick :options="gallerySlickOptions">
          <template v-for="(item, i) in galleryProductHomeItemsRandom">
            <div class="gallery-item" :key="i">
              <div class="image-wrap cursor-pointer" @click="
                  showProductDetailOrOpenSea(
                    item.idx,
                    item.filterDisplayOnly,
                    item
                  )
                ">
                <template v-if="error[item.idx] === undefined">
                  <img :src="replaceThumbnailUrl(item)" @error="setAltImg($event,item)"/>
                </template>
                <template v-else>
                  <template v-if="isProductFileTypeImage(item.productFileType)">
                    <b-img :src="replaceDisplayCardUrl(item)"></b-img>
                  </template>
                  <template v-else>
                    <video
                      :poster="replaceDisplayPosterUrl(item)"
                      :src="replaceDisplayCardUrl(item)"
                      type="video/webm"
                      muted
                      loop
                      autoplay
                      playsinline
                    ></video>
                  </template>
                </template>
              </div>
              <div class="information cursor-pointer" @click="
                  showProductDetailOrOpenSea(
                    item.idx,
                    item.filterDisplayOnly,
                    item
                  )
                ">
                <div class="title">
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.productName || item.productNameEn }}
                  </template>
                  <template v-else>
                    {{ item.productNameEn || item.productName }}
                  </template>
                </div>
                <div class="artist">
                  by
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.artistName || item.artistNameEn }}
                  </template>
                  <template v-else>
                    {{ item.artistNameEn || item.artistName }}
                  </template>
                </div>
              </div>
            </div>
          </template>
        </slick>
      </div>
    </div> -->

    <!-- <div class="middle-section">
      <div class="content">
        <div class="wave">
          <img src="/img/threespace/icon/wave.gif"/>
        </div>
        <div class="text-wrap" ref="artworkTotalCount">
          <div class="title">Visualize Web3</div>
          <animated-number :value="artworkTotalCount" :duration="2000" :formatValue="formatToPrice" v-if="isActive" class="artwork-number" />
          <div class="middle">Unique Digitals, Ordinals, AI Art and MEMEs</div>
          <div class="bottom">{{ $t("home.main.title3") }}</div>
          <div class="bottom">{{ $t("home.main.title2") }}</div>
        </div>
        <div class="wave">
          <img src="/img/threespace/icon/wave.gif"/>
        </div>
      </div>
    </div>

    <div class="artpool-section">
      <div class="top">
        <div class="text-wrap">
          <div class="title">ART POOL</div>
          <div class="subtitle">{{ $t("home.artpool.description1") }}</div>
          <div class="subtitle">{{ $t("home.artpool.description2") }}</div>
          <div class="subtitle">{{ $t("home.artpool.description3") }}</div>
        </div>
      </div>
      <div class="nft-pool-history">
        <div class="title">PREVIOUS EXHIBITIONS</div>
        <slick :options="artpoolSlickOptions" v-if="artpoolListLoaded">
          <div v-for="(item,i ) in artpoolList"
               :key="'artpool-list' + i"
               @click="onClickArtPoolListItem(item)"
               class="item"
          >
            <div class="img_box">
              <img :src="item.thumbnailImageUrl" />
            </div>
          </div>
        </slick>
      </div>
    </div>

    <div class="description-section">
      <div class="description-item">
        <div class="img">
          <img src="/img/threespace/main/paper-plane.png"/>
        </div>
        <div>
          <div class="title">
            PERSONALIZE YOUR SPACE
          </div>
          <div class="description">
            {{ $t("gallery.mainIcon.info1") }}
          </div>
        </div>
      </div>
      <div class="description-item">
        <div class="img">
          <img src="/img/threespace/main/nft.png"/>
        </div>
        <div>
          <div class="title">
            SHARE YOUR TASTE
          </div>
          <div class="description">
            {{ $t("gallery.mainIcon.info3") }}
          </div>
        </div>
      </div><div class="description-item">
      <div class="img">
        <img src="/img/threespace/main/phone.png"/>
      </div>
      <div>
        <div class="title">
          CARRY YOUR COLLECTION
        </div>
        <div class="description">
          {{ $t("gallery.mainIcon.info2") }}
        </div>
      </div>
    </div><div class="description-item">
      <div class="img">
        <img src="/img/threespace/main/artist.png"/>
      </div>
      <div>
        <div class="title">
          BECOME AN ARTIST
        </div>
        <div class="description">
          {{ $t("gallery.mainIcon.info4") }}
        </div>
      </div>
    </div>
    </div> -->


    <!-- Main Gallery 영역 ================================= -->
<!--    <div class="main_gallery">-->
<!--      <div class="title_wrap">-->
<!--        <div class="title_box">-->
<!--          <div class="title_border"></div>-->
<!--          &lt;!&ndash; <h2>Be your own gallery<span>.</span></h2> &ndash;&gt;-->
<!--          <h2>Get paid to show your <br />favorite digital art NFT<span>.</span></h2>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="gallery_slider">-->
<!--        <slick ref="slick-gallery" :options="_slickOptions">-->
<!--          <template v-for="(item, i) in gallery">-->
<!--            <b-img-->
<!--              :key="i"-->
<!--              :src="`/img/threespace/media/` + item.fileName"-->
<!--            />-->
<!--          </template>-->
<!--        </slick>-->
<!--      </div>-->
<!--      <div class="info_wrap">-->
<!--        <div class="info_box">-->
<!--          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">-->
<!--            <p>내가 소장한 디지털 아트 NFT를 전시하고<br/> 이에 대한 보상을 받으세요.</p>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            &lt;!&ndash; <p class="en">Curate your space with collectible digital art<br/>created by independent artists</p> &ndash;&gt;-->
<!--            <p class="en">Give your art the benefit of appreciation by participating in exhibitions and events.-->
<!--              Utilize your NFT as proof of ownership to lock copyright for both the artist and the collector</p>-->
<!--            -->
<!--          </template>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!-- Artwork Counting 영역 ================================= -->
<!--    <div class="artwork_counting">-->
<!--      <div class="artwork_counting_wrap">-->
<!--        <div class="d-flex" ref="artworkTotalCount">-->
<!--          <div class="info mobile">More than <animated-number :value="artworkTotalCount" :duration="2000" :formatValue="formatToPrice" v-if="isActive" class="count" /> unique artwork.</div>-->
<!--          <div class="info pc">More than</div>-->
<!--          <div class="count_box pc">-->
<!--            <animated-number :value="artworkTotalCount" :duration="2000" :formatValue="formatToPrice" v-if="isActive" class="count" />-->
<!--          </div>-->
<!--          <div class="info pc"> unique artwork.</div>-->
<!--        </div>-->
<!--        <div class="title_box title_box_small">-->
<!--          <div class="title_border"></div>-->
<!--          <h2>Use your NFTs to participate <br />in exhibitions<span>.</span></h2>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!-- intro gallery 영역 ================================= -->
<!--    <div class="intro_gallery">-->
<!--      <div class="left">-->
<!--        <div class="title">-->
<!--          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">-->
<!--            <span>-->
<!--              세번째공간 아트풀은 NFT를 활용하여 작가와<br/>-->
<!--              관객을 직접 연결, 다양한 작품들을 간편하게<br/>-->
<!--              감상할 수 있습니다.-->
<!--            </span>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            <span class="en">-->
<!--              Artists and collectors can participate in online and offline exhibitions by simply staking NFTs in the 'Art Pool'-->
<!--            </span>-->
<!--          </template>-->
<!--        </div>-->
<!--        <div class="media_box" v-if="!isMobile">-->
<!--          <video-->
<!--            src="/img/threespace/media/main-playlist-01.mp4"-->
<!--            type="video/webm"-->
<!--            muted-->
<!--            loop-->
<!--            autoplay-->
<!--            playsinline-->
<!--          ></video>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="right" v-if="!isMobile">-->
<!--        <div class="media_box">-->
<!--          <video-->
<!--            src="/img/threespace/media/main-playlist-02.mp4"-->
<!--            type="video/webm"-->
<!--            muted-->
<!--            loop-->
<!--            autoplay-->
<!--            playsinline-->
<!--          ></video>-->
<!--        </div>-->
<!--        <div class="media_box">-->
<!--          <b-img src="/img/threespace/media/main-playlist-03.gif" />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="playlist_slide" v-if="isMobile">-->
<!--        <b-carousel v-model="slide" fade>-->
<!--          <template v-for="(item, i) in playlist">-->
<!--            <b-carousel-slide :key="'slide' + i">-->
<!--              <template #img v-if="item.video !== true">-->
<!--                <img :src="`/img/threespace/media/` + item.fileName" />-->
<!--              </template>-->
<!--              <template #img v-else>-->
<!--                <video-->
<!--                  :src="`/img/threespace/media/` + item.fileName"-->
<!--                  type="video/webm"-->
<!--                  muted-->
<!--                  loop-->
<!--                  autoplay-->
<!--                  playsinline-->
<!--                ></video>-->
<!--              </template>-->
<!--            </b-carousel-slide>-->
<!--          </template>-->
<!--        </b-carousel>-->
<!--      </div>-->
<!--    </div>-->

    <!-- site schematic 영역 ================================= -->
<!--    <div class="site_schematic">-->
<!--      <div class="title_box">-->
<!--        <div class="title_border"></div>-->
<!--        <h2>How it works<span>.</span></h2>-->
<!--      </div>-->
<!--      <div class="wrap">-->
<!--        <div class="item1">-->
<!--          <img src="/img/threespace/main/how-it-works1.png" />-->
<!--          <div class="text-box">-->
<!--            <div class="title"><span>01</span>{{ $t("home.howItWorks.title1") }}</div>-->
<!--            <div class="description">{{ $t("home.howItWorks.description1") }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="item2">-->
<!--          <img src="/img/threespace/main/how-it-works2.png" />-->
<!--          <div class="text-box">-->
<!--            <div class="title"><span>02</span>{{ $t("home.howItWorks.title2") }}</div>-->
<!--            <div class="description" v-html="$t('home.howItWorks.description2')"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="item3">-->
<!--          <img src="/img/threespace/main/how-it-works3.png" />-->
<!--          <div class="text-box">-->
<!--            <div class="title"><span>03</span>{{ $t("home.howItWorks.title3") }}</div>-->
<!--            <div class="description">{{ $t("home.howItWorks.description3") }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="item4">-->
<!--          <img src="/img/threespace/main/how-it-works4.png" />-->
<!--          <div class="text-box">-->
<!--            <div class="title"><span>04</span>{{ $t("home.howItWorks.title4") }}</div>-->
<!--            <div class="description">{{ $t("home.howItWorks.description4") }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <!-- trending now 영역 ================================= -->
    <!-- <div class="trending_now">
      <div class="title_box">
        <h2>TRENDING NOW</h2>
        <b-button @click="onClickViewMore">
          VIEW MORE
        </b-button>
      </div>
      <div class="card_list_wrap">
        <div class="trending-now-wrap">
          <template
            v-if="
              galleryProductHomeItems === undefined ||
              galleryProductHomeItems === null ||
              galleryProductHomeItems.length === 0
            "
          >
            <div class="col-12 py-5 text-center">
              <p class="font-lg">{{ $t("product.noResult") }}</p>
            </div>
          </template>
          <template v-for="(item, i) in galleryProductHomeItems">
            <div :key="i">
              <div
                class="card_list_item cursor-pointer"
                @click="
                  showProductDetailOrOpenSea(
                    item.idx,
                    item.filterDisplayOnly,
                    item
                  )
                "
              >
                <v-lazy :options="{ threshold: 0.5 }" transition="fade-transition">
                  <div class="card_media_box" @contextmenu.prevent.stop>
                    <h3>
                      <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                        {{ item.productName || item.productNameEn }}
                      </template>
                      <template v-else>
                        {{ item.productNameEn || item.productName }}
                      </template>
                    </h3>
                      <template v-if="isProductFileTypeImage(item.productFileType)">
                        <template v-if="error[item.idx]===undefined">
                          <img :src="replaceThumbnailUrl(item)" @error="setAltImg($event,item)"/>
                        </template>
                        <template v-else>
                          <b-img :src="replaceDisplayCardUrl(item)"></b-img>
                        </template>
                      </template>
                      <template v-else>
                        <video
                          :poster="replaceDisplayPosterUrl(item)"
                          :src="replaceDisplayCardUrl(item)"
                          type="video/webm"
                          muted
                          loop
                          autoplay
                          playsinline
                        ></video>
                      </template>
                  </div>
                </v-lazy>
                <div class="card_title_box">
                  <div class="artist_name" @click.stop="onClickArtist(item.idxMember)">
                    <template v-if="item.profileImage === undefined">
                      <CImg
                        class="c-avatar-img mr-2"
                        height="25"
                        src="/img/avatars/profile-gray-person.jpg"
                      />
                    </template>
                    <template v-else>
                      <CImg
                        class="c-avatar-img mr-2"
                        height="25"
                        :src="item.profileImageUrl"
                      />
                    </template>
                    <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                      {{ item.artistName || item.artistNameEn }}
                    </template>
                    <template v-else>
                      {{ item.artistNameEn || item.artistName }}
                    </template>
                  </div>
                  <div class="card_price_box">
                    <div class="text_wrap" :class="{soldout: item.status === 'SOLDOUT'}">
                      <template v-if="item.status === 'SOLDOUT'">
                        <template v-if="item.buyerNickname !== undefined && item.buyerNickname !== ''">
                          <div>Owned by</div>
                          <div>@{{item.buyerNickname}}</div>
                        </template>
                        <template v-else>
                          $t("product.statusSoldOut")
                        </template>
                      </template>
                      <template v-else-if="item.status === 'EXHIBIT'">
                        <div class="reserve">{{ $t("product.statusExhibit") }}</div>
                      </template>
                      <template
                        v-else-if="
                        activeLanguage !== undefined &&
                        activeLanguage === 'en' &&
                        isCoinPriceNonZero(item.nftType) &&
                        isAcceptableNftType(item.nftType)
                      "
                      >
                        {{ getProductCoinPrice(item.productPrice, item.nftType) }} {{getCoinCurrencyByNftType(item.nftType)}}
                      </template>
                      <template v-else>
                        {{ item.productPrice | currency }} {{ item.productPrice !== undefined && item.productPrice > 0 ? "KRW" : $t("product.statusExhibit") }}
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div> -->

    <!-- partners 영역 ================================= -->
    <!-- <div class="main_partners">
      <div class="title_box">
        <h2>{{ $t("product.partnersTitle") }}</h2>
      </div>
      <div class="row main_partners_wrap">
        <template v-for="(item, i) in partners">
          <div class="main_partner_box" :key="i">
            <b-img :src="`/img/threespace/partners/` + item.fileName" />
          </div>
        </template>
      </div>
    </div>

    <div class="main_partners">
      <div class="title_box">
        <h2>BACKERS</h2>
      </div>
      <div class="row main_partners_wrap">
        <template v-for="(item, i) in backers">
          <div class="main_partner_box" :key="i">
            <b-img :src="`/img/threespace/partners/` + item.fileName" />
          </div>
        </template>
      </div>
    </div> -->

    <!-- simple ImagePopup -->
    <!-- <SimpleImagePopupByTP
      ref="simpleImagePopupByEvent"
      :activeLanguage="activeLanguage"
      @onClickPopupNoneSeenWeekBtn="onClickPopupNoneSeenWeekBtn"
      @onClickPopupMoreBtn="onClickPopupMoreBtn"
    /> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
// import AnimatedNumber from "animated-number-vue";

import coinUtils from "../../mixins/CoinUtils";
// import SimpleImagePopupByTP from "./event/SimpleImagePopupByTP3";
import IntroArtwork from './components/IntroArtWork/IntroArtwork.vue';

export default {
  name: "Home",
  components: {
    IntroArtwork
  },
  props: {
    referral: {
      type: String,
      default: ""
    }
  },
  mixins: [coinUtils],
  metaInfo() {
    return {
      title: this.$t("meta.home.title"),
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.home.title") },
        { vmid: "description", name: "description", content: this.$t("meta.home.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.home.title") },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.home.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ]
    }
  },
  data() {
    return {
      initialAutoplaySpeed: 5500,
      slideAutoplaySpeeds: [12000,5500,5500,5500,5500,5500],
      mainTitle: [
        "ART POOL",
        "EDITIONS",
        "ORDINALS",
        "AI",
        "MEMEs",
        "Bring Art to Life \nLife to Art"
      ],
      mainDescription: [
        "<div>Dive into our immersive Art Pool,</div><div>where Artists and Collectors can showcase their collections</div>",
        "<div>Discover and collect unique art</div><div>by upcoming artists</div>",
        "<div>Permanently store art directly on Bitcoin</div>",
        "<div>Create your own AI generated NFTs</div><div>- Easy to use</div><div>- Customizable</div><div>- Copyright protected</div>",
        "<div>Build web3 culture with 3space Art</div>"
      ],
      videoOptions: {
        muted: true
      },
      isVideoLoading: true,
      videoSource: '/img/threespace/main/main-banner.mp4',
      placeholderImage: '/img/threespace/main/main-banner.png',
      slickOptions: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
      },
      artpoolSlickOptions: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 10000,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 1550,
            settings: {
              arrows: false,
              dots: false,
              infinite: true,
              slidesToShow: 6,
              autoplay: true,
              autoplaySpeed: 0,
              speed: 10000,
              cssEase: 'linear',
            }
          },
          {
            breakpoint: 960,
            settings: {
              arrows: false,
              dots: false,
              infinite: true,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 2,
              autoplay: true,
              autoplaySpeed: 0,
              speed: 10000,
              cssEase: 'linear',
            }
          }
        ]
      },
      gallerySlickOptions: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 10000,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 1550,
            settings: {
              arrows: false,
              dots: false,
              infinite: true,
              slidesToShow: 3,
              autoplay: true,
              autoplaySpeed: 0,
              speed: 10000,
              cssEase: 'linear',
            }
          },
          {
            breakpoint: 960,
            settings: {
              arrows: false,
              dots: false,
              infinite: true,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1,
              autoplay: true,
              autoplaySpeed: 0,
              speed: 10000,
              cssEase: 'linear',
            }
          }
        ]
      },
      isBusy: false,
      gallery: [
        { fileName: "main-gallery-01.jpg" },
        { fileName: "main-gallery-02.jpg" },
        { fileName: "main-gallery-03.jpg" },
        { fileName: "main-gallery-04.jpg" }
      ],
      partners: [
        { fileName: "shinhan_card.png" },
        { fileName: "LX.png" },
        { fileName: "HDC_Labs.png" },
        { fileName: "HyundaiOilbank.png" },
        { fileName: "komsco.png" },
        { fileName: "ncc_logo-variations-final_.png" },
        { fileName: "groundx.png" },
        { fileName: "polygon.png" },
        { fileName: "korbit.png" },
        { fileName: "HashKeyNFT.png" },
        { fileName: "NFTAsia.png" },
        { fileName: "HAVAH.png" },
        { fileName: "SOMESING.svg" },
        { fileName: "BLUE_CANVAS.png" },
        { fileName: "neopin.png" },
        { fileName: "ym-star-entertainment.png" },
        { fileName: "tae-young.svg" },
        { fileName: "art-dubai.png" },
      ],
      backers: [
        { fileName: "MODORI.png" },
        { fileName: "ALPHA_NONCE_xbg.png" },
        { fileName: "499BLOCK.png" },
        { fileName: "FBG_Capital.png" },
        { fileName: "BLOCK_CRAFTERS.png" },
        { fileName: "intellicode.png" },
        { fileName: "klaytnFoundation.png" },
        { fileName: "kibo.png" },
      ],
      slide: 0,
      playlist: [
        { fileName: "main-playlist-01.mp4", video: true },
        { fileName: "main-playlist-02.mp4", video: true },
        { fileName: "main-playlist-03.gif", video: false }
      ],
      isActive: false,
      error: {},
      currentPage: 1,
      sort: [
        { id: "idx", desc: "desc" }
      ],
      sortBy: "",
      sortDesc: false,
      artpoolListLoaded: false,
      headerHeight: 100,
      sceneScale: 1
    };
  },
  computed: {
    ...mapState("gallery", ["galleryProductHomeItems", "artworkTotalCount", "galleryProductHomeItemsRandom"]),
    ...mapState("event", ["eventInfo"]),
    ...mapState("artpool", [
      "artpoolItems",
    ]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    _slickOptions() {
      return this.slickOptions
    },
    artpoolList () {
      if (this.artpoolItems.content !== undefined && this.artpoolItems.content.length > 0) {
        return this.artpoolItems.content;
      } else {
        return [];
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getTickers();
      this.requestArtworkTotalCount();
      this.requestGalleryProductItems();
      this.requestGalleryProductRandom();
      this.requestCurrentEventInfo(); // 이벤트 팝업
      this.requestArtpools();

      this.updateHeaderHeight(); 
      this.updateSceneScale(); 

      // 회원가입 레퍼럴
      if (this.referral !== undefined && this.referral !== null && this.referral !== "") {
        //this.$log.log("referral: ", this.referral);
        localStorage.setItem("referral3spaceJoin", this.referral);
      }

      // 법인작가 초기 패스워드인경우 비밀번호 변경페이지로 이동
      if (this.$store.state["auth"].isCorporation && this.$store.state["auth"].isInitPwd) {
        alert("개인정보 보호를 위해 초기 비밀번호를 변경해주세요.")
        this.$router.push({ name: "MyPageUpdate", params: { activeTab: 2 }})
      }
    });

    window.addEventListener('resize', this.updateHeaderHeight);
    window.addEventListener('resize', this.updateSceneScale);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeaderHeight);
    window.removeEventListener('resize', this.updateSceneScale);
  },
  methods: {
    ...mapActions("gallery", ["getGalleryProductsHome", "getArtworkTotalCount", "getGalleryProductRandom"]),
    ...mapActions("coin", ["getTickers"]),
    ...mapActions("event", ["getEvent"]),
    ...mapActions("artpool", ["getArtpools"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    handleAfterChange(e) {
      const newAutoplaySpeed = this.slideAutoplaySpeeds[e.target.slick.currentSlide] || 5500;
      this.$refs.slick.setOption(
        'autoplaySpeed', newAutoplaySpeed, true
      );
    },
    onVideoLoaded() {
      this.isVideoLoading = false;
    },
    requestArtworkTotalCount() {
      this.getArtworkTotalCount()
        .then(() => {
          // 작품수 카운팅 효과
          this.initIntersectionObserver();
        })
    },
    initIntersectionObserver() {
      const io = new IntersectionObserver(async ([entry], observer) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);

          // 작품 건수 카운팅
          this.isActive = true;

          // 다시 관찰하는 경우
          //observer.observe(entry.target);
        }
      }, {
        threshold: 1.0
      })
      io.observe(this.$refs.artworkTotalCount);
    },
    formatToPrice(value) {
      return `${Number(value).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}`;
    },
    requestGalleryProductRandom() {
      this.getGalleryProductRandom()
        .then(() => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    requestGalleryProductItems() {
      this.isBusy = true;
      this.getGalleryProductsHome()
        .then(() => {
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    isProductFileTypeImage(_productFileType) {
      return !!(_productFileType !== undefined && _productFileType !== null && _productFileType.startsWith("image"));
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayPosterUrl !== undefined && item.displayPosterUrl.startsWith("https://ipfs.io/")) {
          return item.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayCardUrl(item) {
      if (
        item.displayCardFile !== undefined &&
        item.displayCardFile !== null &&
        item.displayCardFile !== "" &&
        item.displayCardFile === "THREESPACE"
      ) {
        return item.displayCardUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayCardUrl !== undefined && item.displayCardUrl.startsWith("https://ipfs.io/")) {
          return item.displayCardUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayCardUrl;
      }
    },
    showProductDetailOrOpenSea(idx, filterDisplayOnly, item) {
      if (!filterDisplayOnly) {
        this.$router.push("/art/" + idx);
      } else {
        if (
          item.nftType === "KLIP" &&
          item.klipNftTokenId !== undefined &&
          item.klipNftTokenId !== null &&
          item.klipNftTokenId !== ""
        ) {
          // TODO 컨트랙주소 고정
          const url = "https://opensea.io/assets/klaytn/0x2abec25064cbcd8d3c298903098840f95a432073/" + item.klipNftTokenId;
          window.open(url, "_blank");
        }
      }
    },
    onClickArtist(idxMember) {
      // this.$router.push("/artist/" + idxArtist);
      let idxEncode = window.btoa(idxMember);
      this.$router.push("/collections/" + idxEncode);
    },
    onClickViewMore() {
      this.$router.push("/drops");
    },
    toggleClickMuted() {
      this.videoOptions.muted = !this.videoOptions.muted;
    },
    // Event
    requestCurrentEventInfo() {
      const idxEvent = process.env.VUE_APP_CURRENT_EVENT_IDX;
      this.$log.log("Event:", idxEvent);
      if (idxEvent !== undefined && idxEvent !== null && idxEvent !== "" && idxEvent !== "0") {
        // 이벤트 정보 조회
        this.getEvent({idxEvent: idxEvent}).then(() => {
          if (this.eventInfo !== undefined && this.eventInfo.status !== undefined) {
            if (this.eventInfo.status === "EVENT_IN_PROGRESS" && this.$cookies.get("popupNotSeenForWeek3spaceEvent") === null) {
              this.$refs["simpleImagePopupByEvent"].show();
            }
          }
        });
      }
    },
    onClickPopupNoneSeenWeekBtn() {
      this.$cookies.set("popupNotSeenForWeek3spaceEvent", "done", "7d");
      this.$refs["simpleImagePopupByEvent"].hide();
    },
    onClickPopupMoreBtn() {
      // this.$router.push("/drops");
      this.$router.push("/features/15");
      this.$refs["simpleImagePopupByEvent"].hide();
    },
    setAltImg(event, item){
      if(!item.error){
        event.target.src = this.replaceDisplayCardUrl(item);
      }
      this.error[item.idx] = true
      this.$forceUpdate();
      item.error = true;
    },
    replaceThumbnailUrl(item) {
      const w = "400";
      const h = "463";
      const thumbnailUrl = `https://collection.3space.art/${process.env.NODE_ENV}/${item.idx}.jpg`
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return `${thumbnailUrl}?src=${item.displayPosterUrlThreespace}&w=${w}&h=${h}`; // 3space S3 URL
      } else {
        if (item.displayPosterUrl !== undefined && item.displayPosterUrl.startsWith("https://ipfs.io/")) {
          item.displayPosterUrl = item.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return `${thumbnailUrl}?src=${item.displayPosterUrl}&w=${w}&h=${h}`;
      }
    },
    onClickArtPoolListItem(artpool) {
      if(artpool.roundNo < 3 ) {
        this.$router.push({ name: "NftPoolStake", params: { idxArtpool: artpool.idx }})
      } else if(artpool.roundNo >=3 ) {
        this.$router.push({ name: "NftPoolDetail", params: { idxArtpool: artpool.idx }})
      }
    },
    requestArtpools() {
      this.getArtpools(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then((data) => {
          if(data.content.length > 0) {
            this.artpoolListLoaded = true;
          }
        })
        .catch(error => {
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }
      return params;
    },

    updateHeaderHeight() {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (width < 600) {
        this.headerHeight = 64;
      } else if (width < 960) {
        this.headerHeight = 64;
      } else {
        this.headerHeight = 64;
      }
    },
    updateSceneScale() {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (width < 640) {
        this.sceneScale = 1.4;
      } 
      else {
        this.sceneScale = 0.8;
      }
    },
    lockScroll() {
      document.documentElement.classList.add('lock-scroll');
      document.body.classList.add('lock-scroll');
    },
    unlockScroll() {
      document.documentElement.classList.remove('lock-scroll');
      document.body.classList.remove('lock-scroll');
    },
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>
